<template>
  <div class="News position-relative view__news animation" style="animation-delay: 2s;">
    <v-container class="same-padding-appbar py-0" fluid>
      <!-- botón de filtrar -->
      <v-row>
        <v-col cols="12" class="position-relative" style="z-index: 2">
          <span
            class="Projects__filter black--text d-inline-block"
            v-bind:class="{ 'active-menu-filter': open_sidebar }"
            @click="open_sidebar = !open_sidebar"
            >Filtrar publicaciones</span
          >
        </v-col>
      </v-row>
      <!-- /botón de filtrar -->
    </v-container>

    <!-- *********inicia news********* -->
    <News :allowLimit="allowLimit" :limit="limit" :filterByName="filterByName" :changeContent="2"></News>
    <!-- *********/termina news********* -->

    <!-- *********inicia sidebar********* -->
    <div @mouseleave="open_sidebar = false">
      <sideBar
        :open="open_sidebar"
        :changeContent="2"
        @close="open_sidebar = false"
        @updatingCategories="UpdatedCategoriesNews"
        @updatingFilterNews="updatedFilters"
      ></sideBar>
    </div>
    <!-- *********/termina sidebar********* -->
  </div>
</template>

<script>
import News from "../components/Globals/NewsLimit";
import sideBar from "../components/Globals/SideBar";

export default {
  components: {
    News,
    sideBar,
  },
  data() {
    return {
      news: [],
      filterByName: "",
      limit: 1,
      allowLimit: false,
      open_sidebar: false,
      categories: [],
    };
  },
  mounted() {
    this.$emit("update", 1);

    if(this.$route.params.filter){
      this.open_sidebar = true;
    }
  },
  methods: {
    UpdatedCategoriesNews: function (newValues) {
      this.categories = newValues;
    },
    updatedFilters: function (newValue) {
      this.filterByName = newValue;
    },
  },
};
</script>